ACC.digitalAccountsValidation = {
    _autoload: ['bindInputsChangeValidation', 'bindEmailInputValidation'],

    // eslint-disable-next-line consistent-return
    isEmailValid(form) {
        // eslint-disable-next-line max-len
        const divToAppendErrorMessage = ACC.digitalAccountsAimsWeb.isAimswebNewFormUsed() ? "div[id$='digitalAccountsQualifiedUserAddEmailSection']:eq(1)" : "div[id$='digitalAccountsQualifiedUserAddEmailSection']:eq(0)";
        if (!ACC.digitalAccountsValidation.isEmailPopulated()) {
            // eslint-disable-next-line max-len
            ACC.digitalAccountsViewHelper.addQualifiedUserErrorMessage($('.js-digital-accounts-step').data('user-null-error'), divToAppendErrorMessage);
            return true;
        }
        if (ACC.digitalAccounts.isQualifiedUsersSelectElementUsed()) {
            ACC.digitalAccountsValidation.addQualifiedCustomerByEmailAsSelectOption(form, divToAppendErrorMessage);
        } else {
            ACC.digitalAccountsValidation.addQualifiedCustomerByEmailAsRadioButton(form, divToAppendErrorMessage);
        }
    },

    addQualifiedCustomerByEmailAsSelectOption(form, divToAppendErrorMessage) {
        $.post({
            url: `${ACC.config.encodedContextPath}/digital-accounts/getCustomerByEmail`,
            data: form.serialize(),
            beforeSubmit() {
                ACC.digitalAccountsViewHelper.setButtonDisableState('.js-qualified-user-step-continue', true);
            },
            success(response) {
                if (response !== null && response.errorMessage === null) {
                    ACC.digitalAccountsValidation.addAndPreselectQualifiedUserAsSelectOption(response);
                    ACC.digitalAccountsValidation.validateFieldInputs();
                } else {
                    // eslint-disable-next-line max-len
                    ACC.digitalAccountsViewHelper.addQualifiedUserErrorMessage($('.js-digital-accounts-step').data('user-notfound-error'), divToAppendErrorMessage);
                }
            },
        });
    },

    addQualifiedCustomerByEmailAsRadioButton(form, divToAppendErrorMessage) {
        $.post({
            url: form.data('adddigitalaccount-url'),
            data: form.serialize(),
            beforeSubmit() {
                ACC.digitalAccountsViewHelper.setButtonDisableState('.js-qualified-user-step-continue', true);
            },
            success(response) {
                if (ACC.digitalAccountsValidation.isDigitalAccountValid(response)) {
                    ACC.digitalAccountsValidation.addAndPreselectQualifiedUserAsRadioButton(response);
                    ACC.digitalAccountsValidation.validateFieldInputs();
                } else if (response.includes('text.checkout.qualifieduser.notfound')) {
                    // eslint-disable-next-line max-len
                    ACC.digitalAccountsViewHelper.addQualifiedUserErrorMessage($('.js-digital-accounts-step').data('user-notfound-error'), divToAppendErrorMessage);
                } else if (response.includes('digital.accounts.step2.customer.qualified.user.api.call.fail')) {
                    // eslint-disable-next-line max-len
                    ACC.digitalAccountsValidation.addQualifiedUserApiErrorMessage($('.js-digital-accounts-step').data('api-fail-message'), divToAppendErrorMessage);
                } else if (response.includes('digital.accounts.step2.customer.qualified.multiple.user.account.found')) {
                    // eslint-disable-next-line max-len
                    ACC.digitalAccountsViewHelper.addQualifiedUserErrorMessage($('.js-digital-accounts-step').data('multiple-user-found-error'), divToAppendErrorMessage);
                }
            },
        });
    },

    addQualifiedUserApiErrorMessage(error, divToAppendErrorMessage) {
        const isAimswebNewForm = ACC.digitalAccountsAimsWeb.isAimswebNewFormUsed();
        // eslint-disable-next-line max-len
        const qualifiedUserLabel = !isAimswebNewForm ? $($('#label-qualifiedUser'), divToAppendErrorMessage) : $($('.js-qualified-user-step-continue'), divToAppendErrorMessage);
        const contactSupportMsg = $('.js-digital-accounts-step').data('customer-support-msg');
        ACC.digitalAccountsViewHelper.removeQualifiedUserErrorMessage();
        if (!isAimswebNewForm) {
            qualifiedUserLabel.after(`<label id="aimsWebQualifiedContact" class="qualifiedcontact c-radiobtn c-boxed">
                <div class="c-alert c-alert--inline c-alert--error">
                    <div class="qualifiedcontact c-alert__content .c-alert--inline">
                        <button class="c-alert__close" aria-hidden="true" data-dismiss="alert" type="button">×</button>
                        <div class="help-block">${error}<span class="has-error">
                        <a href="${ACC.config.qualifiedContactContactSupportUrl}"> ${contactSupportMsg}</a><span>
                        </div>
                    </div>
                </div>
            </label>`);
        } else {
            qualifiedUserLabel.before(`<label id="qualified-contact" class="qualifiedcontact c-radiobtn c-boxed">
                <div class="c-alert c-alert--inline c-alert--error">
                    <div class="qualifiedcontact c-alert__content .c-alert--inline">
                        <button class="c-alert__close" aria-hidden="true" data-dismiss="alert" type="button">×</button>
                        <div class="help-block">
                        ${error}<span class="has-error">
                        <a href="${ACC.config.qualifiedContactContactSupportUrl}"> ${contactSupportMsg}</a><span>
                        </div>
                    </div>
                </div>
            </label>`);
        }
        qualifiedUserLabel.closest('.form-group').addClass('has-error');
    },

    isDigitalAccountValid(response) {
        return !($.parseHTML(response)[0].id === 'digitalAccountCreationErrorMessage');
    },

    addAndPreselectQualifiedUserAsRadioButton(response) {
        // eslint-disable-next-line max-len
        const addNewUserLabel = ACC.digitalAccountsAimsWeb.isAimswebNewFormUsed() ? $('#label-qualifiedUserNew') : $('#label-qualifiedUser');
        const qualifiedUserLabelSelector = $.escapeSelector($.parseHTML(response)[0].id);
        if (!($(`#${qualifiedUserLabelSelector}`).length > 0)) {
            addNewUserLabel.before(response);
        }
        ACC.digitalAccountsViewHelper.preselectQualifiedUserLabel(qualifiedUserLabelSelector);
        $('.js-noQualifiedMembersInOrgStep').hide();
        $('#qualified-contact').remove();
        ('#aimsWebQualifiedContact').remove();
    },

    addAndPreselectQualifiedUserAsSelectOption(user) {
        // eslint-disable-next-line max-len
        const qualifiedUsersSelect = $(`#${ACC.digitalAccounts.getQualifiedUsersSelectElementId()}`);
        const existingQualifiedUserOption = qualifiedUsersSelect.find(`option[value=${user.originalUid}]`);

        if (qualifiedUsersSelect.length > 0) {
            if (existingQualifiedUserOption.length === 0) {
                qualifiedUsersSelect.find('option:last').before($('<option/>', {
                    value: user.originalUid,
                    text: user.fullName,
                    disabled: user.disabled,
                }));
            }
            if (!user.disabled) {
                qualifiedUsersSelect.find(`option[value=${user.originalUid}]`).prop('selected', true).change();
            } else {
                qualifiedUsersSelect.find('option:first').prop('selected', true).change();
            }
        }
    },

    isEmailPopulated() {
        return ACC.digitalAccountsValidation.isDigitalAccountQualifiedUserFormPopulated()
               || ACC.digitalAccountsValidation.isDigitalAccountQualifiedUserNewFormPopulated();
    },

    isDigitalAccountQualifiedUserFormPopulated() {
        if ($('input[name="digitalAccountQualifiedUserForm.email"]').val()) {
            return true;
        }
        return false;
    },

    isDigitalAccountQualifiedUserNewFormPopulated() {
        if ($('input[name="digitalAccountQualifiedUserNewForm.email"]').val()) {
            return true;
        }
        return false;
    },

    bindInputsChangeValidation() {
        $('#qglobalAccountsSelector').change(ACC.digitalAccountsValidation.validateFieldInputs);
        $('#qglobalSelectedQualifiedUser-qualifiedUserID').change(ACC.digitalAccountsValidation.validateFieldInputs);
        $('#existingAimswebTermsConditions').change(ACC.digitalAccountsValidation.validateFieldInputs);
        $('#newAimswebTermsConditions').change(ACC.digitalAccountsValidation.validateFieldInputs);
        $('#selectedQualifiedUser-qualifiedUserID').change(ACC.digitalAccountsValidation.validateFieldInputs);
        // eslint-disable-next-line max-len
        $('#existingAimswebSelectedQualifiedUser-qualifiedUserID').change(ACC.digitalAccountsValidation.validateFieldInputs);
        $('#newAimswebSelectedQualifiedUser-qualifiedUserID').change(ACC.digitalAccountsValidation.validateFieldInputs);

        // eslint-disable-next-line max-len
        $('#digitalAccountAimswebAccountNumber').on('change paste keyup', ACC.refinements.debounce(ACC.digitalAccountsValidation.validateFieldInputs, 100));
        // eslint-disable-next-line max-len
        $('#selectedQualifiedUserEmail').on('change paste keyup', ACC.refinements.debounce(ACC.digitalAccountsValidation.validateFieldInputs, 100));
        // eslint-disable-next-line max-len
        $('#newAimswebSelectedQualifiedUserEmail').on('change paste keyup', ACC.refinements.debounce(ACC.digitalAccountsValidation.validateFieldInputs, 100));
        // eslint-disable-next-line max-len
        $('#existingAimswebSelectedQualifiedUserEmail').on('change paste keyup', ACC.refinements.debounce(ACC.digitalAccountsValidation.validateFieldInputs, 100));
        // eslint-disable-next-line max-len
        $('#qglobalSelectedQualifiedUserEmail').on('change paste keyup', ACC.refinements.debounce(ACC.digitalAccountsValidation.validateFieldInputs, 100));
    },

    validateFieldInputs() {
        let isSubmitButtonEnabled = false;
        const qGlobalStepValid = ACC.digitalAccountsQGlobal.isQGlobalStepValid();
        const hasQGlobalProducts = ACC.digitalAccountsQGlobal.hasQGlobalProducts();
        const hasAimswebProducts = ACC.digitalAccountsAimsWeb.hasAimswebProducts();
        const hasAimswebNew = ACC.digitalAccountsAimsWeb.validateAimswebNew();
        const hasAimswebExisting = ACC.digitalAccountsAimsWeb.validateAimswebExisting();
        const isValidForAimsWebOnlyCart = ACC.digitalAccountsAimsWeb.isValidForAimsWebOnlyCart();
        const noDigitalProductsValid = ACC.digitalAccountsValidation.validateNoDigital();

        if (ACC.digitalAccounts.getSelectedContextPartyType() === 'ORGANIZATION') {
            if (hasQGlobalProducts || hasAimswebProducts) {
                if (ACC.digitalAccountsAimsWeb.isAimswebStep()) {
                    const isAimswebStepValid = hasAimswebExisting || hasAimswebNew;
                    if (!hasQGlobalProducts) {
                        isSubmitButtonEnabled = isValidForAimsWebOnlyCart;
                    } else {
                        isSubmitButtonEnabled = isAimswebStepValid;
                    }
                }
                if (ACC.digitalAccountsQGlobal.isQglobalStep()) {
                    isSubmitButtonEnabled = qGlobalStepValid;
                }
            } else {
                isSubmitButtonEnabled = noDigitalProductsValid;
            }
        } else if (hasQGlobalProducts) {
            isSubmitButtonEnabled = qGlobalStepValid;
        } else {
            isSubmitButtonEnabled = noDigitalProductsValid;
        }
        ACC.digitalAccountsViewHelper.setButtonDisableState('.js-qualified-user-step-continue', !isSubmitButtonEnabled);
    },

    validateNoDigital() {
        if (ACC.digitalAccounts.getSelectedContextPartyType() === 'ORGANIZATION') {
            // eslint-disable-next-line max-len
            if (($('#selectedQualifiedUser-qualifiedUserID option:selected').val() !== '' && $('#selectedQualifiedUser-qualifiedUserID option:selected').val() !== undefined)
                || $('.qualifiedUsersSelectorRadioBtn:checked').length !== 0) {
                // eslint-disable-next-line max-len
                if ($('#selectedQualifiedUser-qualifiedUserID option:selected').val() !== '+' && $('.qualifiedUsersSelectorRadioBtn:checked').val() !== '+') {
                    return true;
                }
            }
            // eslint-disable-next-line max-len
        } else if (ACC.digitalAccounts.hasCustomerQualifications() && ACC.digitalAccounts.getSelectedContextPartyType() === 'PERSON') {
            return true;
        }

        return false;
    },

    bindEmailInputValidation() {
        const emailInput = $('#selectedQualifiedUserEmail');
        emailInput.on('focusout', () => {
            const value = emailInput.val();
            if (value) {
                $('#selectedQualifiedUserEmail').val(value.trim());
            }
        });
    },
};
